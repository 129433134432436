import React from "react";
import largeFormat from "../utilities/largeform.png";
import DTF from "../utilities/DTF.png";
import laser from "../utilities/laser.png";
import CNC from "../utilities/CNC.png";
import Digital from "../utilities/Digital.png";
import UV from "../utilities/UV.png";
import vector4 from "../utilities/vector4.svg";
import { Link } from "react-router-dom";

function Services() {
  return (
    <>
      <div className="services">
        <span className="title">Our Creative Solutions</span>
        <span className="paragraph">
          Discover Endless Possibilities with Our Creative Solutions
        </span>
        <div className="CARD">
          <div className="cardd">
            <img className="cardd__img" src={largeFormat} alt="a Large Format printer" />
            <div id="cardd">
              <span id="t1">Large Format Printing</span>
              <span id="t2">
              Experience the exceptional quality of our large format printing services. Perfect for banners, stickers, rollups and many more.
              </span>
              <span id="t3">
                Read more <img src={vector4} alt="arrow icon" />
              </span>
            </div>
          </div>
          <div className="cardd">
            <img className="cardd__img" src={Digital} alt="a Digital Printer" />
            <div id="cardd">
              <span id="t1">Digital Printing</span>
              <span id="t2">
              Achieve high-quality, vibrant prints.We offer quick turnaround times & precision, perfect for brochures, flyers, business cards, and more
              </span>
              <span id="t3">
              <Link to="/digital">Read more <img src={vector4} alt="arrow icon" /></Link>
              </span>
            </div>
          </div>

          <div className="cardd">
            <img className="cardd__img" src={CNC} alt="a CNC router" />
            <div id="cardd">
              <span id="t1">CNC Router</span>
              <span id="t2">
              Utilize the power of CNC technology for your engraving and cutting needs. We offer unparalleled accuracy and detail.
              </span>
              <span id="t3">
                Read more <img src={vector4} alt="arrow icon" />
              </span>
            </div>
          </div>
          <div className="cardd">
            <img  className="cardd__img" src={laser} alt="a Laser cutting machine" />
            <div id="cardd">
              <span id="t1">Laser Cutting & Engraving</span>
              <span id="t2">
              Precision and creativity come together with our laser engraving and cutting services. From intricate designs to clean cuts...
              </span>
              <span id="t3">
                Read more <img src={vector4} alt="arrow icon" />
              </span>
            </div>
          </div>
          <div className="cardd">
            <img className="cardd__img" src={DTF} alt="a DTF printer" />
            <div id="cardd">
              <span id="t1">No-cut printing</span>
              <span id="t2">
              Discover the efficiency and clean finish of our no-cut printing services. Perfect for creating seamless prints on various materials
              </span>
              <span id="t3">
                Read more <img src={vector4} alt="arrow icon" />
              </span>
            </div>
          </div>
          <div className="cardd">
            <img className="cardd__img" src={UV} alt="a uv printer" />
            <div id="cardd">
              <span id="t1">UV Printing</span>
              <span id="t2">
              Experience durable prints on various surfaces ensuring long-lasting, high-quality results that are resistant to fading and wear.
              </span>
              <span id="t3">
                Read more <img src={vector4} alt="arrow icon" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
