import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";



const firebaseConfig = {
    apiKey: "AIzaSyBHwfegUG1PZxtLt9Gsd60pNH9f3h-nw4k",
    authDomain: "point-one-media.firebaseapp.com",
    projectId: "point-one-media",
    storageBucket: "point-one-media.appspot.com",
    messagingSenderId: "936868219648",
    appId: "1:936868219648:web:18ab8f48b93f31fda501e5",
    measurementId: "G-ZK3DKVW4WC",
};

// Initialize Firebase
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const storage = getStorage(app)
