import React from "react";
import logo from "../utilities/Point-One-Logo.png";

function Navbar1() {
  return (
    <>
      <div className="navMain">
        <div className="navContent">
          <div>
            <img src={logo} alt="" />
          </div>
          <div>
            <ul className="navMenu">
              <li>Home</li>
              <li>About</li>
              <li>Services</li>
              <li>Insights</li>
            </ul>
          </div>

          <div className="navButton">
            <button>Get a Quote</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar1;
