import React from "react";
import { useState } from "react";
import { db, storage } from "../config/firebase";
import {collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import Dropzone from "react-dropzone";

function Quote() {
  // const [quoteList, setQuoteList] = useState([]);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [description, setDescription] = useState("");
  const [fileUpload, setFileUpload] = useState(null);

  const quoteCollectionRef = collection(db, "quote");

  // const getQuoteList = async () => {
  //   try {
  //     const data = await getDocs(quoteCollectionRef);
  //     const filteredData = data.docs.map((doc) => ({
  //       ...doc.data(),
  //       id: doc.id,
  //     }));
  //     // setQuoteList(filteredData);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // useEffect(() => {
  //   getQuoteList();
  // }, []);

  //Handle file upload
  const uploadFile = async (clientID) => {
    if (!fileUpload) return;
    const filesFolderRef = ref(
      storage,
      `artworks/${clientID}_${fileUpload.name}`
    );
    try {
      await uploadBytes(filesFolderRef, fileUpload);
      // alert ("file uploaded successfully")
    } catch (err) {
      console.error(err);
    }

    //Get the download URL
    const downloadURL = await getDownloadURL(filesFolderRef);
    return downloadURL;
  };

  // reset form after upload
  const resetForm = () => {
    setName("");
    setCompany("");
    setEmail("");
    setPhoneNumber(0);
    setDescription("");
    setFileUpload(null);
  };


  const onSubmitOrder = async () => {
    try {
      const clientDoc = await addDoc(quoteCollectionRef, {
        fullNames: name,
        companyName: company,
        email: email,
        phoneNumber: phoneNumber,
        description: description,
      });
      const clientID = clientDoc.id;
      const artworkURL = await uploadFile(clientID);

      await addDoc(quoteCollectionRef, {
        fullNames: name,
        companyName: company,
        email: email,
        phoneNumber: phoneNumber,
        description: description,
        artworkURL: artworkURL,
      });
      alert("Order and artwork uploaded successfully!");
      resetForm();
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  return (
    <>
      <main className="YY">
        <div className="">
          <div className="YY">
            <h1 className="">Get a quote</h1>
            <p className="">
              Use a permanent address where you can receive mail.
            </p>

            <div className="Y">
              <div className="y1">
                <label htmlFor="full-name" className="">
                  Full Names
                </label>
                <div className="">
                  <input
                    type="text"
                    name="fullName"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="y2">
                <label htmlFor="" className="">
                  Company name
                </label>
                <div className="">
                  <input
                    type="text"
                    name="companyName"
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
              </div>

              <div className="y3">
                <label htmlFor="email" className="">
                  Email address
                </label>
                <div className="">
                  <input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="y4">
                <label htmlFor="phone number" className="">
                  Phone Number
                </label>
                <div className="">
                  <input
                    type="tel"
                    name="phone"
                    onChange={(e) => setPhoneNumber(Number(e.target.value))}
                  />
                </div>
              </div>

              <div className="y5">
                <label htmlFor="company-name" className="">
                  Description
                </label>
                <div className="">
                  <textarea
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>

              <div className="y6">
                <label htmlFor="cover-photo" className="">
                  Upload artwork
                </label>
                <div className="y6a">
                  <input
                    type="file"
                    onChange={(e) => setFileUpload(e.target.files[0])}
                  />
                  {/* <button onClick={uploadFile}>Upload</button> */}
                </div>
              </div>

              <div className="y7">
                <button className="y7a">Cancel</button>
                <button className="y7b" onClick={onSubmitOrder}>
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          {quoteList.map((quote) => (
            <div>
              <h1>Name: {quote.fullNames}</h1>
              <p>Company Name: {quote.companyName}</p>
              <p>Email: {quote.email}</p>
              <p>Phone Number: {quote.phoneNumber}</p>
              <p>Description: { quote.description}</p>
            </div>
          ))}
        </div> */}
      </main>
    </>
  );
}

export default Quote;
