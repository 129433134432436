import React from "react";
// import Navbar from "./Navbar";
import Navbar1 from "./Navbar1";


function Home() {
  return (
    <>
      <div className="body">
        <Navbar1/>
        <nav/>
        <div className="homeContent">
          <div className="redLine"></div>
          <div className="slogan">
            <span className="slogan1">
              Be <span className="ahead">ahead</span> of the competition. Let's handle your print
              and branding projects
            </span>
          </div>
          <div className="homeBtns">
            <button id="homeBtns1">Sign In</button>
            <button id="homeBtns2">Sign Up</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
